import { RootState } from 'modules/App/store/root-state';

import { namespace } from './actions';
import { DictionariesList, Dictionary, DictionaryName } from './types';

export const getDictionaries = (state: RootState): DictionariesList => state[namespace];

export const getDictionary = (state: RootState, name: DictionaryName): Dictionary => {
  return getDictionaries(state)[name] || {};
};

export const selectors = {
  getDictionary,
  getDictionaries,
};
