import { useMemo } from 'react';

import { useOperatorData } from 'modules/domain/users/hooks';

type OperatorCommissions = {
  replyCommission: number;
  penalty: number;
};

export function useOperatorCommissions(): OperatorCommissions {
  const operator = useOperatorData();

  const commissions = useMemo(
    () => ({
      replyCommission: operator?.commission || 0,
      penalty: operator?.trapPenalty || 0,
    }),
    [operator],
  );

  return commissions;
}
