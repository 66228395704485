import { useState, useMemo, useCallback, useEffect } from 'react';

import { GiftDto } from 'modules/api/dto';

import { filterAvailableGifts, isInPriceLimit } from '../helpers';

import { useGiftsApi } from './use-gifts-api';
import { useGiftsPriceLimitPoller } from './use-gifts-price-limit-poller';

export const useGifts = () => {
  const { getGifts, getGiftsPriceLimit, sendGift: apiSendGift } = useGiftsApi();

  const [gifts, setGifts] = useState<GiftDto[]>([]);
  const [priceLimit, setPriceLimit] = useState(0);

  useEffect(() => {
    getGifts().then(setGifts);
  }, [getGifts]);

  const checkPriceLimit = useCallback(async () => {
    const priceLimit = await getGiftsPriceLimit();
    setPriceLimit(priceLimit);
    return priceLimit;
  }, [getGiftsPriceLimit]);

  // We need to monitor actual gifts price limit to hide gifts when limit is reached
  useGiftsPriceLimitPoller(checkPriceLimit);

  const sendGift = useCallback(
    async (gift: GiftDto) => {
      const priceLimit = await checkPriceLimit();

      if (!isInPriceLimit(gift, priceLimit)) {
        return false;
      }

      const success = await apiSendGift(gift);

      if (success) {
        // TODO: a temporary solution until we don't have events logic implemented.
        // Operator won't be able to send another gift anyway.
        // It's needed to refresh gifts state almost immediately not relying on a poller
        setTimeout(checkPriceLimit, 1000);
      }

      return success;
    },
    [apiSendGift, checkPriceLimit],
  );

  const availableGifts = useMemo(
    () => filterAvailableGifts(gifts, priceLimit),
    [gifts, priceLimit],
  );

  return {
    gifts: availableGifts,
    sendGift,
  };
};
