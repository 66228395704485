import React, { memo, useCallback, useEffect, useState } from 'react';

import { CopilotOption as CopilotOptionType, DialogMessage } from 'modules/domain/dialog/types';
import { useShortcutListener } from 'modules/components/common/hooks/use-shortcut-listener';
import { testId } from 'modules/utils/test-id';
import { useCopilot } from 'modules/domain/dialog/hooks';
import { AutoGeneratedAnswerOptionType } from 'modules/api/dto';

import { useOnDialogInputFocused } from './useOnDialogInputFocused';
import styles from './index.module.css';
import { CopilotOption } from './option';
import { Skeleton } from './skeleton';

type Props = {
  attendeeId: string;
  animatorId: string;
  lastMessage?: DialogMessage;
  onNeedToScrollCopilot: (needToScroll: boolean) => void;
  trapPenalty: number;
};

const isOptionTrap = (option: CopilotOptionType) =>
  option.type === AutoGeneratedAnswerOptionType.Trap;

export const CopilotAnswerOptions = memo((props: Props) => {
  const { attendeeId, animatorId, trapPenalty, lastMessage, onNeedToScrollCopilot } = props;

  const { copilotOptions, loading, selectCopilotOption, notifyAboutTheTrap } = useCopilot({
    attendeeId,
    animatorId,
    lastMessage,
  });

  const [trapActive, setTrapActive] = useState(false);

  const resetTrap = useCallback(() => setTrapActive(false), []);

  useEffect(() => {
    resetTrap();

    if (copilotOptions.length) {
      onNeedToScrollCopilot(true);
    }
  }, [resetTrap, copilotOptions, onNeedToScrollCopilot]);

  useOnDialogInputFocused(resetTrap);

  const handleCopilotOptionSelect = useCallback(
    (optionIndex: number) => {
      const currentCopilotOption = copilotOptions[optionIndex];

      if (isOptionTrap(currentCopilotOption)) {
        setTrapActive(true);
        onNeedToScrollCopilot(true);
        notifyAboutTheTrap(currentCopilotOption);
        return;
      }

      selectCopilotOption(currentCopilotOption);
    },
    [copilotOptions, onNeedToScrollCopilot, selectCopilotOption, notifyAboutTheTrap],
  );

  useShortcutListener(keyNumber => handleCopilotOptionSelect(keyNumber - 1), 'altKey');

  if (loading) {
    return <Skeleton />;
  }

  if (!copilotOptions.length) {
    return null;
  }

  return (
    <div className={styles.optionsList} {...testId('copilot-options-list-populated')}>
      {copilotOptions.map((option, index) => (
        <CopilotOption
          key={option.id}
          text={option.text}
          secondShortcutPart={index + 1}
          onClick={() => handleCopilotOptionSelect(index)}
          /* we assume that we have only one trap at the moment, so we don't need to think what the option that activated trap*/
          showTrapModal={trapActive && isOptionTrap(option)}
          onCloseTrapModal={resetTrap}
          trapPenalty={trapPenalty}
        />
      ))}
    </div>
  );
});
