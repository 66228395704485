import React, { memo, useCallback, useState } from 'react';
import cn from 'classnames';

import closeIcon from 'images/icon-close-big.svg';
import giftIcon from 'images/gift-icon.svg';
import { Image } from 'modules/components/image';
import { useGetShardUri } from 'modules/hooks';
import { testId } from 'modules/utils';
import { useClickOutside } from 'modules/domain/common/hooks';
import { AnimatedGift } from 'modules/components/animated-gift';
import { useGifts } from 'modules/domain/gifts/hooks';
import { GiftDto } from 'modules/api/dto';
import { IconButton } from 'modules/components/common/button';
import { showChatErrorToast } from 'modules/components/chat/helpers';

import styles from './index.module.scss';

const SEND_GIFT_ERROR_TEXT =
  'Unfortunately this user is not able to receive Virtual Gifts at this moment. Please try again later.';

export const GiftsPicker = memo(() => {
  const [opened, setOpened] = useState(false);
  const [sending, setSending] = useState(false);

  const getShardUri = useGetShardUri();
  const { gifts, sendGift } = useGifts();

  const closePicker = useCallback(() => setOpened(false), []);

  const wrapperRef = useClickOutside<HTMLDivElement>({
    condition: opened,
    action: closePicker,
  });

  const handleGiftSelect = async (gift: GiftDto) => {
    closePicker();
    setSending(true);

    const success = await sendGift(gift);
    if (!success) {
      showChatErrorToast(SEND_GIFT_ERROR_TEXT);
    }
    setSending(false);
  };

  if (!gifts.length) {
    return null;
  }

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <IconButton
        disabled={sending}
        {...testId('switch-gifts-popup-button')}
        onClick={() => setOpened(!opened)}
      >
        <img src={giftIcon} alt="gifts" />
      </IconButton>

      <div className={cn(styles.picker, { [styles.show]: opened })}>
        <div
          {...testId('gifts-popup-close-button')}
          className={styles.closeButton}
          onClick={closePicker}
        >
          <img src={closeIcon} alt="close" />
        </div>
        <div className={styles.pickerContent}>
          {gifts.map(gift => {
            return (
              <div
                key={gift.giftId}
                className={styles.pickerItem}
                {...testId(`cheer-${gift.giftId}`)}
              >
                <div className={styles.pickerItemButton} onClick={() => handleGiftSelect(gift)}>
                  {gift.isAnimated ? (
                    <AnimatedGift animationPath={gift.imagePath} />
                  ) : (
                    <Image
                      className={styles.pickerItemImage}
                      src={getShardUri(gift.imagePath)}
                      alt="gift-image"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});
