import { useEffect } from 'react';

import { useApi } from 'modules/api';

import { Dialog } from '../types';

export function useMarkLastMessageAsRead(currentDialog: Dialog) {
  const { messages, animatorId } = currentDialog;
  const lastMessage = messages[0];

  const { dialogsMessages: dialogsMessagesApi } = useApi();

  useEffect(() => {
    if (lastMessage?.id && lastMessage.recipient === animatorId && !lastMessage.read) {
      const { sender, recipient, id } = lastMessage;
      // switching sender and recipient is intended here
      dialogsMessagesApi.markMessageAsRead(recipient, sender, id);
    }
  }, [lastMessage, animatorId, dialogsMessagesApi]);
}
