import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth';
import { useLogger } from 'modules/utils';

import { mapOperatorDtoToOperator } from '../user-adapter';

export const useOperatorApi = () => {
  const operatorId = useOperatorId();
  const { operators: operatorsApi } = useApi();
  const { logError } = useLogger('useOperatorApi');

  const sendActivityStatus = useCallback(async () => {
    try {
      await operatorsApi.sendActivityStatus(operatorId);
    } catch (error) {
      logError(`sendActivityStatus error`, { error });
    }
  }, [operatorsApi, operatorId, logError]);

  const fetchOperatorData = useCallback(async () => {
    try {
      const [operator, commission] = await Promise.all([
        operatorsApi.getOperator(operatorId),
        operatorsApi.getOperatorCommission(operatorId),
      ]);

      return mapOperatorDtoToOperator(operator, commission);
    } catch (error) {
      logError('Fetch operator data error', { error });
      return null;
    }
  }, [operatorsApi, logError, operatorId]);

  return {
    sendActivityStatus,
    fetchOperatorData,
  };
};
