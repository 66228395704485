import { useSelector } from 'react-redux';

import { RootState } from 'modules/App/store/root-state';

import { DatesRange } from '../types';
import { getEarnings, getFullKPI } from '../selectors';

export const useEarningsSelectors = (range: DatesRange) => {
  const earnings = useSelector((state: RootState) => getEarnings(state, range));
  const fullKPI = useSelector((state: RootState) => getFullKPI(state, range));

  return {
    earnings,
    fullKPI,
  };
};
