import { AutoGeneratedAnswersDto, AnimatorPreferencesDto } from 'modules/api/dto';

import { DialogNotes, Metrics } from '../types';
import { AudioMessageTemplate } from '../audio';

import { apiDialogActions } from './api-dialog-actions';
import { entityActions } from './entity-actions';
import { searchMessagesActions } from './search-messages-actions';
import { messageTypingActions } from './message-typing';
import { DialogActions } from './common';

export type SetAnimatorStoryToldPayload = { dialogId: string; storyTold: boolean };
export type SetAnimatorStoryToldAction = {
  type: DialogActions.SetAnimatorStoryTold;
  payload: SetAnimatorStoryToldPayload;
};

export function setAnimatorStoryTold(
  dialogId: string,
  animatorStoryTold: boolean,
): SetAnimatorStoryToldAction {
  return {
    type: DialogActions.SetAnimatorStoryTold,
    payload: { dialogId, storyTold: animatorStoryTold },
  };
}

export type SetDialogNotesPayload = { dialogId: string; input: DialogNotes };
export type SetDialogNotesAction = { type: DialogActions.SetNotes; payload: SetDialogNotesPayload };

export function setDialogNotes(dialogId: string, input: DialogNotes): SetDialogNotesAction {
  return { type: DialogActions.SetNotes, payload: { dialogId, input } };
}

export type SetAnimatorPreferencesPayload = { dialogId: string; input: AnimatorPreferencesDto };
export type SetAnimatorPreferencesAction = {
  type: DialogActions.SetAnimatorPreferences;
  payload: SetAnimatorPreferencesPayload;
};

export function setAnimatorPreferences(
  dialogId: string,
  input: AnimatorPreferencesDto,
): SetAnimatorPreferencesAction {
  return { type: DialogActions.SetAnimatorPreferences, payload: { dialogId, input } };
}

export type SetDialogsMetricsPayload = { metrics: Metrics };
export type SetLastBotMessagePayload = { lastBotMessage: boolean };
export type SetDialogsMetricsAction = { type: DialogActions; payload: SetDialogsMetricsPayload };
export type SetLastBotMessageAction = { type: DialogActions; payload: SetLastBotMessagePayload };

export function setDialogsMetrics(metrics: Metrics): SetDialogsMetricsAction {
  return { type: DialogActions.SetMetrics, payload: { metrics } };
}

export function setLastBotMessage(lastBotMessage: boolean): SetLastBotMessageAction {
  return { type: DialogActions.SetLastBotMessage, payload: { lastBotMessage } };
}

export type SetDialogExplicitStatusPayload = { id: string; status: boolean };
export type SetDialogExplicitStatusAction = {
  type: DialogActions;
  payload: SetDialogExplicitStatusPayload;
};

export function setExplicitStatus(id: string, status: boolean): SetDialogExplicitStatusAction {
  return { type: DialogActions.SetDialogExplicitStatus, payload: { id, status } };
}

export type RemoveMessageByTagPayload = { dialogId: string; tag: string };
export type RemoveMessageByTagAction = { type: DialogActions; payload: RemoveMessageByTagPayload };

function removeMessageByTag(dialogId: string, tag: string): RemoveMessageByTagAction {
  return { type: DialogActions.RemoveMessageByTag, payload: { dialogId, tag } };
}

export type ResetCopilotStateAction = { type: DialogActions.ResetCopilotState };

function resetCopilotState(): ResetCopilotStateAction {
  return { type: DialogActions.ResetCopilotState };
}

export type CopilotLoadSucceedPayload = AutoGeneratedAnswersDto;

function copilotLoadSucceed(copilotData: AutoGeneratedAnswersDto) {
  return {
    type: DialogActions.CopilotLoadSucceed,
    payload: copilotData,
  };
}

function copilotOptionClicked(message: string) {
  return {
    type: DialogActions.CopilotOptionClicked,
    payload: { message: message },
  };
}

export type SetScrollToMessageIdPayload = {
  messageId: number | null;
};

function setScrollToMessageId(messageId: number | null) {
  return {
    type: DialogActions.SetScrollToMessageId,
    payload: { messageId },
  };
}

export type SetAudioMessagesPayload = {
  dialogId: string;
  audioMessages: AudioMessageTemplate[];
};
export type SetAudioMessagesAction = {
  type: DialogActions.SetAudioMessages;
  payload: SetAudioMessagesPayload;
};

function setAudioMessages(
  dialogId: string,
  audioMessages: AudioMessageTemplate[],
): SetAudioMessagesAction {
  return {
    type: DialogActions.SetAudioMessages,
    payload: { dialogId, audioMessages },
  };
}

export type ResetAudioMessagesPayload = {
  dialogId: string;
};
export type ResetAudioMessagesAction = {
  type: DialogActions.ResetAudioMessages;
  payload: ResetAudioMessagesPayload;
};

function resetAudioMessages(dialogId: string): ResetAudioMessagesAction {
  return {
    type: DialogActions.ResetAudioMessages,
    payload: { dialogId },
  };
}

export {
  setUnansweredUnpaidMessageAnswered,
  setApiUploadMediaFailure,
  setApiUploadMediaSuccess,
  setApiUploadMediaProgress,
  setApiUploadMediaStart,
  setApiSendMessageFailure,
  setApiMessageSent,
  setDialogMessages,
  setDialogAllMessagesLoaded,
} from './api-dialog-message-actions';

// TODO: get rid of this actions object. Replace it with direct exports
export const actions = {
  ...apiDialogActions,
  ...searchMessagesActions,
  ...entityActions,
  ...messageTypingActions,
  setAnimatorStoryTold,
  setDialogNotes,
  setAnimatorPreferences,
  setDialogsMetrics,
  setLastBotMessage,
  setExplicitStatus,
  removeMessageByTag,
  resetCopilotState,
  copilotLoadSucceed,
  copilotOptionClicked,
  setScrollToMessageId,
  setAudioMessages,
  resetAudioMessages,
};
