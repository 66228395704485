import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IFeaturesDomain } from './contracts';
import { FeatureAvailableDto } from './dto';

export class FeaturesDomain implements IFeaturesDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getIcebreakersAvailability(userId: string): Promise<FeatureAvailableDto> {
    return this.transport
      .get<FeatureAvailableDto>(`/users/${userId}/features/icebreakers`)
      .then(response => response.data);
  }

  getVoiceMessagesAvailability(userId: string): Promise<FeatureAvailableDto> {
    return this.transport
      .get<FeatureAvailableDto>(`/users/${userId}/features/voice.messages`)
      .then(response => response.data);
  }
}
