import { useCallback } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { useConfig } from 'modules/config';
import { useOperatorId } from 'modules/domain/auth';
import { clientConfig } from 'modules/config/client';

type LogContext = {
  service?: string;
  apiHost?: string;
  userId?: string;
} & {
  [key: string]: any;
};

type LogLevel = 'log' | 'info' | 'warn' | 'error';

let initialized = false;

export function init() {
  const { env } = clientConfig;

  if (env === 'debug') {
    // If you want to test datadog logs in debug mode just disable this check
    return;
  }

  datadogLogs.init({
    clientToken: clientConfig.datadog.clientToken[env === 'production' ? 'sdv' : 'np'],
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    env,
    version: clientConfig.revision,
  });

  console.log(`Initialize Datadog with env: ${env}`);

  initialized = true;
}

const logBase = (level: LogLevel, message: string, context: LogContext) => {
  console[level](message, context);

  if (!initialized) {
    return;
  }

  const messageContext = {
    host: 'Once Multichat Web',
    ...context,
  };

  if (level === 'error' && context.error) {
    return datadogLogs.logger.error(message, messageContext, context.error);
  }

  return datadogLogs.logger[level](message, messageContext);
};

export const useLogger = (service: string) => {
  const { api } = useConfig();
  const operatorId = useOperatorId();

  const log = useCallback(
    (level: LogLevel, message: string, context?: object) => {
      logBase(level, message, {
        service,
        apiHost: api,
        operatorId,
        ...context,
      });
    },
    [api, operatorId, service],
  );

  const logError = useCallback(
    (message: string, context?: object) => {
      log('error', message, context);
    },
    [log],
  );

  return {
    log,
    logError,
  };
};

export { logBase as log };
