import { FullKPIDto } from 'modules/api/dto';

export const calculateTotalKPI = (fullKPI: FullKPIDto | null) => {
  if (fullKPI === null) {
    return 0;
  }
  const total = Object.values(fullKPI).reduce((subTotal, kpiItem) => {
    if (kpiItem.indicator) {
      return subTotal + kpiItem.indicator;
    }
    return subTotal;
  }, 0);

  return total;
};
