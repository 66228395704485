import React from 'react';

export const IcebreakerPickerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="1" y="1" width="18" height="18" rx="3" fill="#F031D2" />
    <path
      d="M13.9651 13.7067C14.0841 13.8802 13.8724 14.0821 13.7044 13.9554L12.3241 12.914C11.0436 11.9479 9.30519 11.8972 8.00842 12.788L6.2927 13.9667C6.12507 14.0818 5.91819 13.8749 6.03334 13.7073L7.21196 11.9916C8.10278 10.6948 8.05205 8.95643 7.08601 7.67595L6.04464 6.2956C5.91787 6.12757 6.11976 5.91591 6.29332 6.03487L7.87971 7.12226C9.14076 7.98665 10.7863 8.00345 12.03 7.16463L13.7086 6.03242C13.8758 5.91966 14.0803 6.12419 13.9676 6.29137L12.8354 7.97004C11.9965 9.2137 12.0133 10.8592 12.8777 12.1203L13.9651 13.7067Z"
      fill="white"
    />
  </svg>
);
