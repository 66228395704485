import { useMemo } from 'react';

import { UserTag } from 'modules/api/dto';

import { useUserTags } from './use-user-tags';

export const useUserHasTag = (userId: string, tag: UserTag) => {
  const userTags = useUserTags(userId);

  const hasTag = useMemo(() => userTags.includes(tag), [userTags, tag]);

  return hasTag;
};
