import { EarningsDto, FullKPIDto } from 'modules/api/dto';

import { DatesRange } from './types';

export enum EarningsActions {
  SetEarnings = 'earnings.set-earnings',
  SetKPI = 'earnings.set-kpi',
}

export type EarningsActionPayload<P> = {
  range: DatesRange;
} & P;

export type SetEarningsPayload = EarningsActionPayload<{ earnings: EarningsDto }>;
export type SetEarningsAction = { type: EarningsActions.SetEarnings; payload: SetEarningsPayload };

export function setEarnings(payload: SetEarningsPayload): SetEarningsAction {
  return { type: EarningsActions.SetEarnings, payload };
}

export type SetKPIPayload = EarningsActionPayload<{ fullKPI: FullKPIDto }>;
export type SetKPIAction = { type: EarningsActions.SetKPI; payload: SetKPIPayload };

export function setKPI(payload: SetKPIPayload): SetKPIAction {
  return { type: EarningsActions.SetKPI, payload };
}

export const actions = {
  setEarnings,
  setKPI,
};
