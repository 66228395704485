import { getCurrentDialog, getFoundDialog } from 'modules/domain/dialog/selectors';
import { Dialog } from 'modules/domain/dialog/types';
import { useRootSelector } from 'modules/hooks/redux';

export const useCurrentDialog = () => {
  const currentDialog = useRootSelector<Dialog | undefined>(state => getCurrentDialog(state));
  const foundDialog = useRootSelector<Dialog | null>(state => getFoundDialog(state));

  return foundDialog || currentDialog;
};
