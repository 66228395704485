import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { getShardUri as getShardUriBase } from 'modules/utils';

export const useGetShardUri = () => {
  const { sharding: shardingConfig } = useConfig();

  const getShardUrl = useCallback(
    path => {
      return getShardUriBase(path, shardingConfig);
    },
    [shardingConfig],
  );

  return getShardUrl;
};
