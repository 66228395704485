import { useMemo } from 'react';

import { UserExperimentTag } from 'modules/api/dto';

import { useUserExperimentTags } from './use-user-experiment-tags';

export const useUserHasExperimentTag = (userId: string, tag: UserExperimentTag) => {
  const experimentTags = useUserExperimentTags(userId);

  const hasTag = useMemo(() => experimentTags.includes(tag), [experimentTags, tag]);

  return hasTag;
};
