import { DictionaryName, DictionaryDto } from 'modules/api/dto';

export const namespace = 'dictionaries';

export enum DictionaryActions {
  SetDictionary = 'dictionaries.set-dictionary',
}

export type SetDictionaryPayload = { name: DictionaryName; input: DictionaryDto };
export type SetDictionaryAction = { type: DictionaryActions; payload: SetDictionaryPayload };

export function setDictionary(name: DictionaryName, input: DictionaryDto): SetDictionaryAction {
  return { type: DictionaryActions.SetDictionary, payload: { name, input } };
}

export const actions = {
  setDictionary,
};
