export function convertDateToTimeStamp(date: number | string) {
  return new Date(date).getTime();
}

const HALF_DAY_DELTA = 0.5;

const NOON = 12;
const SECOND = 1000;
const MINUTES_HOUR = 60;
const DAY = 24;

function convertDaysToMs(days: number) {
  return days * DAY * MINUTES_HOUR * MINUTES_HOUR * SECOND;
}

export function isDateInRange(
  today: number,
  month: number,
  day: number,
  before: number,
  after: number,
): boolean {
  const dateUTC = Date.UTC(new Date(today).getFullYear(), month, day, NOON, 0, 0);
  const delta = today - dateUTC;
  const beforeMs = convertDaysToMs(before + HALF_DAY_DELTA);
  const afterMs = convertDaysToMs(after + HALF_DAY_DELTA);

  return -beforeMs <= delta && delta <= afterMs;
}
