import React, { memo, useRef } from 'react';
import classNames from 'classnames';

import { Button } from 'modules/components/common/button/base';
import { ModalModel } from 'modules/domain/modals';
import { DialogModel } from 'modules/domain/dialog';
import { MediaSelectorModalData } from 'modules/constants';

import { WebcamModalStatus } from '../types';

import styles from './index.module.scss';

const photoWidth = 850;
const photoHeight = 650;
const filenamePrefix = 'img_';

type Props = {
  cameraEnabled: boolean;
  closeModal: () => void;
  setModalStatus: (status: WebcamModalStatus) => void;
  videoRecordingEnabled: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
};

export const WebcamSection = memo((props: Props) => {
  const { cameraEnabled, closeModal, setModalStatus, videoRecordingEnabled, videoRef } = props;
  const photoRef = useRef<HTMLCanvasElement>(null);

  const { activeModalData: data } = ModalModel.hooks.useSelectors<MediaSelectorModalData>();
  const { dialogId } = data!;

  const { sendPhotoMessageWithFileUpload } = DialogModel.hooks.useDialogMessagesApi(dialogId);

  const takePhoto = () => {
    if (photoRef.current && videoRef.current) {
      photoRef.current.width = photoWidth;
      photoRef.current.height = photoHeight;

      const ctx = photoRef.current?.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, photoRef.current.width, photoRef.current.height);

        setModalStatus(WebcamModalStatus.PhotoCaptured);
      }
    }
  };

  const retakePhoto = () => {
    setModalStatus(WebcamModalStatus.VideoRecording);
  };

  const sendPhoto = () => {
    photoRef.current?.toBlob(photoBlob => {
      if (photoBlob) {
        const lastModified = new Date().getTime();
        const fileName = filenamePrefix + String(lastModified);

        const file: File = new File([photoBlob], fileName, { lastModified, type: photoBlob.type });

        sendPhotoMessageWithFileUpload(file);
        closeModal();
      }
    });
  };

  return (
    <div className={classNames(styles.webcamContent, !cameraEnabled && styles.webcamContentHidden)}>
      <video ref={videoRef} className={styles.videoContainer} />

      <canvas
        ref={photoRef}
        className={classNames(
          styles.photoContainer,
          videoRecordingEnabled && styles.webcamContentHidden,
        )}
      />

      <div className={styles.takePhotosContainer}>
        {videoRecordingEnabled ? (
          <Button type="button" size="md" onClick={takePhoto} className={styles.takePhotoButton}>
            Take photo
          </Button>
        ) : (
          <>
            <Button
              type="button"
              size="md"
              onClick={retakePhoto}
              className={styles.takePhotoButton}
            >
              Retake
            </Button>
            <Button
              type="submit"
              size="md"
              theme="green"
              onClick={sendPhoto}
              className={styles.takePhotoButton}
            >
              Send
            </Button>
          </>
        )}
      </div>
    </div>
  );
});
