import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { MultiChatReducer } from '../common/types';

import { namespace, DictionaryActions, SetDictionaryPayload } from './actions';
import { DictionariesList } from './types';

type CommonPayload = SetDictionaryPayload;

interface CustomReducerMap extends ReducerMap<DictionariesList, CommonPayload> {
  [DictionaryActions.SetDictionary]: ReducerMapValue<DictionariesList, SetDictionaryPayload>;
}

const initialState: DictionariesList = {};

const reducerMapping: CustomReducerMap = {
  [DictionaryActions.SetDictionary]: (state, { payload }) =>
    update(state, {
      $apply: (dictionaries: DictionariesList) => ({
        ...dictionaries,
        [payload.name]: payload.input,
      }),
    }),
};

export const reducer: MultiChatReducer<DictionariesList, CommonPayload> = {
  [namespace]: handleActions(reducerMapping, initialState),
};
