import { EarningsDto } from 'modules/api/dto';
import {
  calculateBonusIncome,
  calculateTotalIncome,
  getNumberOfBonuses,
} from 'modules/domain/earnings/utils';

type IncomeData = {
  repliesNumber: number;
  repliesPayment: number;
  benefitsNumber: number;
  benefitsPayment: number;
  kpiEffectPayment: number;
  totalIncome: number;
  penalties: number;
};

export const calculateIncomeData = (earnings: EarningsDto | null): IncomeData => {
  if (!earnings) {
    return {
      repliesNumber: 0,
      repliesPayment: 0,
      benefitsNumber: 0,
      benefitsPayment: 0,
      kpiEffectPayment: 0,
      totalIncome: 0,
      penalties: 0,
    };
  }

  const totalIncome = calculateTotalIncome(earnings);
  const benefitsPayment = calculateBonusIncome(earnings);
  const penalties = earnings.TrapPenalties?.TotalBonusValue || 0;
  const kpiEffectPayment = earnings.ReplyValueWithKpi - earnings.Value;

  return {
    repliesNumber: earnings.Replies,
    repliesPayment: earnings.Value,
    benefitsNumber: getNumberOfBonuses(earnings),
    benefitsPayment,
    kpiEffectPayment,
    totalIncome,
    penalties,
  };
};
