import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { DictionaryName } from 'modules/api/dto';
import { useLogger } from 'modules/utils';

import { setDictionary } from '../actions';

const dictionariesList = Object.values(DictionaryName);

export const useDictionaryApi = () => {
  const dispatch = useDispatch();
  const { dictionaries: dictionariesApi } = useApi();
  const { logError } = useLogger('useDictionaryApi');

  const fetchAllDictionaries = useCallback(
    async (language: string) => {
      try {
        const requests = dictionariesList.map(dictionaryName =>
          dictionariesApi.getDictionary(dictionaryName, language),
        );

        const responses = await Promise.all(requests);

        batch(() => {
          dictionariesList.forEach((dictionaryName, index) =>
            dispatch(setDictionary(dictionaryName, responses[index])),
          );
        });
      } catch (error) {
        logError('fetchAllDictionaries error', { language, error });
      }
    },
    [dispatch, dictionariesApi, logError],
  );

  return {
    fetchAllDictionaries,
  };
};
