import { useOperatorApi } from 'modules/domain/users/hooks';
import { useConfig } from 'modules/config';
import { usePoller } from 'modules/hooks';

export const useOperatorActivitySender = () => {
  const { sendActivityStatus } = useOperatorApi();
  const { operatorActivitySenderInterval } = useConfig();

  usePoller(sendActivityStatus, operatorActivitySenderInterval);
};
