import { useEffect, useState } from 'react';

import { Operator } from '../types';

import { useOperatorApi } from './use-operator-api';

export const useOperatorData = () => {
  const { fetchOperatorData } = useOperatorApi();
  const [operatorData, setOperatorData] = useState<Operator | null>(null);

  useEffect(() => {
    fetchOperatorData().then(setOperatorData);
  }, [fetchOperatorData]);

  return operatorData;
};
