import React, { memo } from 'react';

import { ChatWrapper as Chat } from 'modules/components/chat/wrapper';
import { Loader } from 'modules/components/loader';
import { Dialog } from 'modules/domain/dialog/types';
import { Operator } from 'modules/domain/users/types';

type Props = {
  noNewChats: boolean;
  dialog: Dialog | undefined;
  operator: Operator | null;
};

export const ChatWithEmptyStates = memo((props: Props) => {
  const { noNewChats, dialog, operator } = props;

  if (noNewChats) {
    return (
      <div className="finished-dialogs-wrapper">
        You have replied in all chats. Update the queue pressing Next for the new ones in a few
        minutes.
      </div>
    );
  }

  if (dialog) {
    return <Chat currentDialog={dialog} operator={operator} />;
  }

  return (
    <div className="loader-wrapper">
      <Loader />
    </div>
  );
});
