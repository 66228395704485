import { EarningsDto } from 'modules/api/dto';

import { getBonusProgramsIncome } from './getBonusProgramsIncome';

export function calculateBonusIncome(earnings: EarningsDto | null) {
  if (!earnings) {
    return 0;
  }

  const {
    FirstPurchaseBonus,
    MediaViewBonus,
    AnsweredFollowUpBonus,
    AnsweredUnpaidBonus,
    GiftsReplyBonus,
    BonusPrograms,
  } = earnings;

  const bonusProgramsIncome = getBonusProgramsIncome(BonusPrograms);

  const bonusIncome = [
    FirstPurchaseBonus,
    MediaViewBonus,
    AnsweredUnpaidBonus,
    AnsweredFollowUpBonus,
    GiftsReplyBonus,
  ]
    .filter(Boolean)
    .reduce((acc, bonus) => {
      return acc + (bonus?.TotalBonusValue || 0);
    }, bonusProgramsIncome);

  return bonusIncome;
}
