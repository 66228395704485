import { useCallback } from 'react';

import { parseDialogId } from 'modules/utils';
import { useAudioMessagesApi } from 'modules/domain/dialog/audio';
import { useCurrentDialog, useDialogActionsById } from 'modules/domain/dialog/hooks';

export const useAudioMessagesTemplates = () => {
  const dialog = useCurrentDialog();
  const dialogId = dialog?.id || '';
  const { setAudioMessages, resetAudioMessages } = useDialogActionsById(dialogId);
  const { getAudioMessageTemplates, checkAudioMessagesAvailable } = useAudioMessagesApi();

  const loadAudioMessagesTemplates = useCallback(
    (reset?: boolean) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      if (reset) {
        resetAudioMessages();
      }

      checkAudioMessagesAvailable(attendeeId).then(available => {
        if (available) {
          getAudioMessageTemplates(animatorId, attendeeId).then(setAudioMessages);
        }
      });
    },
    [
      dialogId,
      resetAudioMessages,
      setAudioMessages,
      getAudioMessageTemplates,
      checkAudioMessagesAvailable,
    ],
  );

  return { audioMessagesTemplates: dialog?.audioMessages, loadAudioMessagesTemplates };
};
