import React, { memo } from 'react';

import { ModalModel } from 'modules/domain/modals';
import { useDialogAvailableMedia } from 'modules/domain/media/hooks';
import { MediaSelectorModalData } from 'modules/constants';

import { MediaItem } from './media-item';
import styles from './index.module.scss';
import { useSendDialogMedia } from './use-send-dialog-media';

export const MediaSelectorModal = memo(() => {
  const { activeModalData: data } = ModalModel.hooks.useSelectors<MediaSelectorModalData>();
  const { dialogId, operatorCommission } = data!;
  const { sendDialogMedia, messageSending } = useSendDialogMedia(dialogId);
  const media = useDialogAvailableMedia(dialogId);

  const onMediaSelect = async (baseUrl: string) => {
    sendDialogMedia(baseUrl, media.length);
  };

  return (
    <div className={styles.container}>
      <h4>Send a Photo</h4>
      <p className={styles.text}>
        Send one of these photos to a client once in a while and get ${operatorCommission} if the
        photo is viewed. If a client&apos;s reply is photo you will also get ${operatorCommission}{' '}
        for it.
      </p>

      <div className={styles.list}>
        {!media.length ? (
          <p>There are no photos</p>
        ) : (
          media.map(({ baseUrl, basename }) => (
            <MediaItem
              key={basename}
              id={basename}
              baseUrl={baseUrl}
              disabled={messageSending}
              onItemClick={() => onMediaSelect(baseUrl)}
            />
          ))
        )}
      </div>
    </div>
  );
});
