import React, { RefObject } from 'react';

import './styles/index.scss';

type Props = {
  logScrollableContainer: RefObject<HTMLDivElement>;
  scrollHandler: (event: React.UIEvent<HTMLDivElement>) => void;
  messagesCount: number;
};

export class ChatLog extends React.PureComponent<Props> {
  getSnapshotBeforeUpdate(prevProps: Readonly<Props>): number | null {
    const { logScrollableContainer, messagesCount } = this.props;
    if (prevProps.messagesCount !== messagesCount && logScrollableContainer.current) {
      return logScrollableContainer.current.scrollHeight - logScrollableContainer.current.scrollTop;
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  componentDidUpdate(prevProps: Readonly<Props>, prevState: {}, snapshot: number) {
    if (snapshot !== null) {
      const { logScrollableContainer } = this.props;
      if (logScrollableContainer.current) {
        logScrollableContainer.current.scrollTop =
          logScrollableContainer.current.scrollHeight - snapshot;
      }
    }
  }

  render() {
    const { logScrollableContainer, children, scrollHandler } = this.props;
    return (
      <div className="chat-log" ref={logScrollableContainer} onScroll={scrollHandler}>
        {children}
      </div>
    );
  }
}
