import React, { memo, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';

import {
  useDialogActionsById,
  useDialogMessagesApi,
  useDialogMessagesSearchState,
} from 'modules/domain/dialog/hooks';
import { RoundButton } from 'modules/components/common/button/round';
import { ChevronUpIcon } from 'modules/components/icon/chevron/up';
import { ChevronDownIcon } from 'modules/components/icon/chevron/down';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
  dialogId: string;
  onClose: () => void;
};

export const ChatSearchWidget = memo((props: Props) => {
  const { dialogId, onClose } = props;
  const { searchMessages, switchSearchMessages } = useDialogMessagesApi(dialogId);
  const { switchOffSearchMessages } = useDialogActionsById(dialogId);
  const messagesSearchState = useDialogMessagesSearchState(dialogId);

  const searchActive = !!messagesSearchState;
  const totalCount = messagesSearchState?.totalCount || 0;
  const currentCount = totalCount - (messagesSearchState?.currentSearchedMessageIndex || 0);
  const someResultFound = !!totalCount;

  const hasPreviousSearchResults = someResultFound && currentCount > 1;
  const hasNextSearchResults = someResultFound && currentCount < totalCount;

  const handleClose = useCallback(() => {
    switchOffSearchMessages();
    onClose();
  }, [onClose, switchOffSearchMessages]);

  const handleTextChange = useDebouncedCallback(searchMessages, 500);

  const handleClickUp = useCallback(() => switchSearchMessages('up'), [switchSearchMessages]);
  const handleClickDown = useCallback(() => switchSearchMessages('down'), [switchSearchMessages]);

  return (
    <div className={styles.panel}>
      <input
        {...testId('in-chat-search-field')}
        className={styles.messageInput}
        type="text"
        placeholder="Type something to search in this chat…"
        onChange={({ target: { value } }) => handleTextChange(value)}
      />
      {searchActive && (
        <div {...testId('in-chat-search-progress-value')} className={styles.progress}>
          {currentCount}/{totalCount}
        </div>
      )}
      <div className={styles.activityButtons}>
        <RoundButton
          size="md"
          theme="darker"
          {...testId('in-chat-search-go-down-button')}
          className={classNames(styles.nextButton, {
            [styles.disabledButton]: !hasNextSearchResults,
          })}
          onClick={handleClickDown}
        >
          <ChevronDownIcon />
        </RoundButton>
        <RoundButton
          size="md"
          theme="darker"
          {...testId('in-chat-search-go-up-button')}
          className={classNames(styles.prevButton, {
            [styles.disabledButton]: !hasPreviousSearchResults,
          })}
          onClick={handleClickUp}
        >
          <ChevronUpIcon />
        </RoundButton>
        <RoundButton
          {...testId('in-chat-search-close-button')}
          size="md"
          theme="darker"
          className={styles.closeButton}
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path d="M4 4L12 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 4L4 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </RoundButton>
      </div>
    </div>
  );
});
