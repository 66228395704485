import { History } from 'history';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as fastAnswersReducer } from 'modules/domain/fast-answers/reducer';
import { UserModel } from 'modules/domain/users';
import { DialogModel } from 'modules/domain/dialog';
import { reducer as mediaReducer } from 'modules/domain/media/reducer';
import { DictionaryModel } from 'modules/domain/dictionaries';
import { ModalModel } from 'modules/domain/modals';
import { reducer as spotifyMatchReducer } from 'modules/domain/spotify-match/reducer';
import { EarningsModel } from 'modules/domain/earnings';

import { RootState } from './root-state';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

export const configureAppStore = (history: History, preloadedState = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rootReducer = combineReducers<RootState>({
    ...UserModel.reducer,
    ...DialogModel.reducer,
    ...mediaReducer,
    ...DictionaryModel.reducer,
    ...ModalModel.reducer,
    ...fastAnswersReducer,
    ...spotifyMatchReducer,
    ...EarningsModel.reducer,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [...getDefaultMiddleware({ serializableCheck: false })],
    preloadedState,
    enhancers: [],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};
