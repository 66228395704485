import { createSelector } from 'reselect';

import { EntityMap } from 'modules/domain/common/types';
import { RootState } from 'modules/App/store/root-state';
import { AnimatorPreferencesDto, DialogMessageDto } from 'modules/api/dto';

import { namespace } from './common';
import { Dialog, DialogModelState, DialogNotes, FastAnswerInTypedMessage, Metrics } from './types';
import { DialogMediaMessage, UnansweredUnpaidDialogState } from './types/dialog';

const getDialogState = (state: RootState): DialogModelState => state[namespace];
const getDialogMap = (state: RootState): EntityMap<Dialog> => getDialogState(state).entities.byId;
const getDialogList = (state: RootState): Dialog[] => Object.values(getDialogMap(state));

const getDialog = (state: RootState, dialogId: string): Dialog => getDialogMap(state)[dialogId];
export const getCurrentDialogId = (state: RootState): string =>
  getDialogState(state).currentDialogId;
export const getLastBotMessage = (state: RootState): boolean =>
  getDialogState(state).lastBotMessage;
export const getDialogsQueueIsEmpty = (state: RootState): boolean =>
  getDialogState(state).dialogsQueueIsEmpty;
export const getCanToggleExplicit = (state: RootState): boolean =>
  getDialogState(state).canToggleExplicit;
export const getTypedMessage = (state: RootState): string =>
  getDialogState(state).typedTextMessage.content;
export const getIsDialogInputFocused = (state: RootState): boolean =>
  getDialogState(state).dialogInputIsFocused;
export const getScrollToMessageId = (state: RootState): number | null =>
  getDialogState(state).scrollToMessageId;

export const getCurrentDialogIsForceLocked = (state: RootState): boolean =>
  getDialogState(state).currentDialogForceLocked;
export const getFastAnswerInTypedMessage = (state: RootState): null | FastAnswerInTypedMessage =>
  getDialogState(state).typedTextMessage.fastAnswer;
export const getCurrentDialog = (state: RootState): Dialog =>
  getDialog(state, getCurrentDialogId(state));
export const getFoundDialogId = (state: RootState): string | null =>
  getDialogState(state).foundDialogId;
export const getFoundDialog = (state: RootState): Dialog | null => {
  const id = getFoundDialogId(state);
  return id ? getDialog(state, id) : null;
};
export const getCopilotState = (state: RootState) => getDialogState(state).copilot;

// first preference: answered sort by newest first
const getAnsweredDialogIdList = (state: RootState): string[] =>
  getDialogList(state)
    .filter(dialog => dialog.answered)
    .sort((a, b) => b.timestamp - a.timestamp)
    .map(dialog => dialog.id);
// second preference: unanswered sort by newest last
const getUnansweredDialogIdList = (state: RootState): string[] =>
  getDialogList(state)
    .filter(dialog => !dialog.answered)
    .sort((a, b) => a.timestamp - b.timestamp)
    .map(dialog => dialog.id);
// queue = [...answeredIds, ...unansweredIds]
export const getSortedDialogIdList = createSelector(
  getAnsweredDialogIdList,
  getUnansweredDialogIdList,
  (answered, unanswered) => {
    return [...answered, ...unanswered];
  },
);

export const getMessageList = (state: RootState, dialogId: string): DialogMessageDto[] =>
  getDialog(state, dialogId)?.messages || [];

export const getMediaMessage = (
  state: RootState,
  dialogId: string,
  messageTag: string,
): DialogMediaMessage | null => getDialog(state, dialogId)?.mediaMessages?.[messageTag] || null;

export const getDialogAnimatorStoryTold = (state: RootState, dialogId: string): boolean =>
  getDialog(state, dialogId)?.animatorStoryTold ?? false;

export const getDialogNotes = (state: RootState, dialogId: string): DialogNotes | undefined =>
  getDialog(state, dialogId)?.notes;

export const getAnimatorPreferences = (
  state: RootState,
  dialogId: string,
): AnimatorPreferencesDto => {
  return getDialog(state, dialogId)?.animatorPreferences || {};
};

export const getDialogsMetrics = (state: RootState): Metrics => getDialogState(state).metrics;

export const getDialogMessagesSearchState = (state: RootState, dialogId: string) =>
  getDialog(state, dialogId)?.searchedMessages;

export const getDialogUnansweredUnpaidState = (
  state: RootState,
  dialogId: string,
): UnansweredUnpaidDialogState => getDialog(state, dialogId)?.unansweredUnpaid || {};

export const getDialogAllMessagesLoaded = (state: RootState, dialogId: string): boolean => {
  return getDialog(state, dialogId)?.allMessagesLoaded ?? false;
};

export const getDialogSomeMessagesLoaded = (state: RootState, dialogId: string): boolean => {
  return getDialog(state, dialogId)?.someMessagesLoaded ?? false;
};
