import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PollerFn, usePoller } from 'modules/hooks';
import { useConfig } from 'modules/config';

import { resetDialogMediaState } from '../actions';

import { useDialogMediaEnabled } from './use-selectors';
import { useDialogMediaApi } from './use-dialog-media-api';

export const useDialogMediaStatePoller = (dialogId: string) => {
  const dispatch = useDispatch();
  const { loadDialogMediaState } = useDialogMediaApi();
  const { chatMediaStatePollingInterval } = useConfig();

  const enabled = useDialogMediaEnabled(dialogId);

  const checkChatMediaState = useCallback<PollerFn>(
    async stopPoller => {
      if (enabled) {
        // No need to poll media state if the media is enabled/available in the dialog
        stopPoller();

        return;
      }

      // keep checking state until chat media enabled
      loadDialogMediaState(dialogId);
    },
    [dialogId, enabled, loadDialogMediaState],
  );

  usePoller(checkChatMediaState, chatMediaStatePollingInterval);

  useEffect(
    () => () => {
      // reset the dialog media state when leaving the dialog
      dispatch(resetDialogMediaState(dialogId));
    },
    [dispatch, dialogId],
  );
};
