import React, { memo } from 'react';

import { ModalModel } from 'modules/domain/modals';
import { useDialogMediaVisible } from 'modules/domain/media/hooks';
import { DialogModel } from 'modules/domain/dialog';
import { ModalNames } from 'modules/domain/modals/types';
import { TooltipMenu } from 'modules/components/tooltip-menu';
import { MenuItemProps } from 'modules/components/tooltip-menu/menu-item';
import { IconButton } from 'modules/components/common/button';
import { getDialogId, testId } from 'modules/utils';
import { MediaSelectorModalData } from 'modules/constants';

import { SendMediaIcon } from './send-media-icon';

type Props = {
  animatorId: string;
  attendeeId: string;
  operatorCommission: string;
};

export const MediaSelectorButton = memo((props: Props) => {
  const { animatorId, attendeeId, operatorCommission } = props;

  const dialogId = getDialogId(animatorId, attendeeId);
  const { sendPhotoMessageWithFileUpload } = DialogModel.hooks.useDialogMessagesApi(dialogId);

  const { openModal } = ModalModel.hooks.useModal();

  const isMediaFromLibraryVisible = useDialogMediaVisible(dialogId);

  const modalData: MediaSelectorModalData = {
    dialogId,
    operatorCommission,
  };

  const showMediaSelector = () => {
    openModal(ModalNames.MediaSelector, modalData);
  };

  const showWebcamModal = () => {
    openModal(ModalNames.WebcamMedia, modalData);
  };

  const options: MenuItemProps[] = [];
  if (isMediaFromLibraryVisible) {
    options.push({
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.56406 11.8721H15.0191L12.6826 8.72468L10.7435 11.2567L9.3525 9.47147L7.56406 11.8721ZM6.54804 14.5836C6.17293 14.5836 5.85587 14.4541 5.59685 14.1951C5.33783 13.936 5.20831 13.619 5.20831 13.2439V3.75674C5.20831 3.38163 5.33783 3.06456 5.59685 2.80553C5.85587 2.54651 6.17293 2.41699 6.54804 2.41699H16.0352C16.4103 2.41699 16.7273 2.54651 16.9864 2.80553C17.2454 3.06456 17.3749 3.38163 17.3749 3.75674V13.2439C17.3749 13.619 17.2454 13.936 16.9864 14.1951C16.7273 14.4541 16.4103 14.5836 16.0352 14.5836H6.54804ZM6.54804 13.5003H16.0352C16.0993 13.5003 16.158 13.4736 16.2115 13.4202C16.2649 13.3667 16.2916 13.308 16.2916 13.2439V3.75674C16.2916 3.69263 16.2649 3.63386 16.2115 3.58043C16.158 3.52701 16.0993 3.50031 16.0352 3.50031H6.54804C6.48393 3.50031 6.42517 3.52701 6.37175 3.58043C6.31832 3.63386 6.2916 3.69263 6.2916 3.75674V13.2439C6.2916 13.308 6.31832 13.3667 6.37175 13.4202C6.42517 13.4736 6.48393 13.5003 6.54804 13.5003ZM3.96473 17.1669C3.58963 17.1669 3.27257 17.0374 3.01354 16.7784C2.75451 16.5193 2.625 16.2023 2.625 15.8272V5.25674H3.70831V15.8272C3.70831 15.8913 3.73502 15.9501 3.78844 16.0035C3.84187 16.0569 3.90063 16.0836 3.96473 16.0836H14.5352V17.1669H3.96473Z"
            fill="black"
          />
        </svg>
      ),
      onClick: showMediaSelector,
      caption: 'Choose from Library',
      testIdPrefix: 'choose-from-library-option',
    });
  }

  options.push(
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M2.41699 15.5836V14.2087H4.41699V5.75674C4.41699 5.38831 4.54818 5.07292 4.81055 4.81056C5.07292 4.54818 5.38831 4.41699 5.75674 4.41699H16.5836V5.50031H5.75674C5.68195 5.50031 5.62051 5.52435 5.57243 5.57243C5.52435 5.62051 5.5003 5.68195 5.5003 5.75674V14.2087H9.78395V15.5836H2.41699ZM12.7802 15.5836C12.5907 15.5836 12.4307 15.5195 12.3002 15.3912C12.1697 15.2629 12.1045 15.1039 12.1045 14.9143V7.83645C12.1045 7.64681 12.1686 7.48676 12.2968 7.35628C12.425 7.22581 12.5839 7.16058 12.7735 7.16058H16.9159C17.1054 7.16058 17.2654 7.22472 17.3959 7.35299C17.5264 7.48128 17.5916 7.64025 17.5916 7.82989V14.9077C17.5916 15.0974 17.5275 15.2574 17.3993 15.3879C17.271 15.5184 17.1122 15.5836 16.9226 15.5836H12.7802ZM13.1878 14.2087H16.5083V8.24387H13.1878V14.2087Z"
            fill="black"
          />
        </svg>
      ),
      onChange: (files: File[]) => {
        if (files?.length) {
          sendPhotoMessageWithFileUpload(files[0]);
        }
      },
      fileInput: true,
      caption: 'Choose from Device',
      testIdPrefix: 'choose-from-device-option',
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9.99933 14.1798C10.9139 14.1798 11.6724 13.8715 12.275 13.2551C12.8775 12.6387 13.1788 11.8871 13.1788 11.0003C13.1788 10.1274 12.8775 9.37932 12.275 8.75593C11.6724 8.13254 10.9139 7.82085 9.99933 7.82085C9.0848 7.82085 8.32626 8.13254 7.7237 8.75593C7.12115 9.37932 6.81987 10.1309 6.81987 11.0107C6.81987 11.8905 7.12115 12.6387 7.7237 13.2551C8.32626 13.8715 9.0848 14.1798 9.99933 14.1798ZM9.99933 13.0965C9.38394 13.0965 8.88073 12.8931 8.4897 12.4864C8.09868 12.0797 7.90316 11.5774 7.90316 10.9795C7.90316 10.3932 8.09868 9.90081 8.4897 9.50214C8.88073 9.10347 9.38394 8.90414 9.99933 8.90414C10.6147 8.90414 11.1179 9.10347 11.509 9.50214C11.9 9.90081 12.0955 10.3932 12.0955 10.9795C12.0955 11.5774 11.9 12.0797 11.509 12.4864C11.1179 12.8931 10.6147 13.0965 9.99933 13.0965ZM3.75577 16.5836C3.39038 16.5836 3.07574 16.4517 2.81185 16.1878C2.54796 15.9239 2.41602 15.6093 2.41602 15.2439V6.75674C2.41602 6.39135 2.54796 6.07671 2.81185 5.81283C3.07574 5.54894 3.39038 5.41699 3.75577 5.41699H6.17562L7.67562 3.41699H12.323L13.823 5.41699H16.2429C16.618 5.41699 16.9351 5.54894 17.1941 5.81283C17.4531 6.07671 17.5826 6.39135 17.5826 6.75674V15.2439C17.5826 15.6093 17.4531 15.9239 17.1941 16.1878C16.9351 16.4517 16.618 16.5836 16.2429 16.5836H3.75577ZM3.75577 15.5003H16.2429C16.3177 15.5003 16.3791 15.4763 16.4272 15.4282C16.4753 15.3801 16.4993 15.3187 16.4993 15.2439V6.75674C16.4993 6.68195 16.4753 6.62051 16.4272 6.57243C16.3791 6.52435 16.3177 6.50031 16.2429 6.50031H13.2653L11.7553 4.50031H8.24131L6.73331 6.50031H3.75577C3.68097 6.50031 3.61954 6.52435 3.57145 6.57243C3.52337 6.62051 3.49933 6.68195 3.49933 6.75674V15.2439C3.49933 15.3187 3.52337 15.3801 3.57145 15.4282C3.61954 15.4763 3.68097 15.5003 3.75577 15.5003Z"
            fill="black"
          />
        </svg>
      ),
      onClick: showWebcamModal,
      caption: 'Take via Webcam',
      testIdPrefix: 'take-via-webcam-option',
    },
  );

  return (
    <TooltipMenu
      options={options}
      trigger={
        <IconButton {...testId('media-selector__button')}>
          <SendMediaIcon />
        </IconButton>
      }
      position={'top-right'}
    />
  );
});
