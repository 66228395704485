import React, { memo } from 'react';

import { testId } from 'modules/utils/test-id';
import { useConfig } from 'modules/config';

import styles from './index.module.scss';

export const AppNameLabel = memo(() => {
  const { appPrettyName } = useConfig();

  return (
    <span {...testId('app-name-label')} className={styles.container}>
      {appPrettyName}
    </span>
  );
});
