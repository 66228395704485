import React, { useCallback, useEffect, useState } from 'react';

const EPSILON = 1;

export const useChatScroll = (onTopTouched = () => {}, onScroll = () => {}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const [isBottomPosition, setIsBottomPosition] = useState(true);

  const scrollHandler = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (!event.currentTarget) {
        return;
      }
      onScroll();
      const { scrollHeight, clientHeight, scrollTop } = event.currentTarget;

      setHasScroll(scrollHeight > clientHeight);
      setScrollPosition(scrollTop);

      // the pixels for clientHeight/scrollTop/scrollHeight are rounded. We should adjust for rounding errors
      setIsBottomPosition(Math.abs(clientHeight + scrollTop - scrollHeight) < EPSILON);
    },
    [onScroll],
  );

  useEffect(() => {
    const isScrollNearTop = scrollPosition <= 75;

    if (hasScroll && isScrollNearTop) {
      onTopTouched();
    }
  }, [scrollPosition, hasScroll, onTopTouched]);

  return {
    scrollHandler,
    isBottomPosition,
  };
};
