import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useOperatorId } from 'modules/domain/auth';
import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

import { DatesRange } from '../types';
import { getDatesRangeMap } from '../constants';
import { actions } from '../actions';

export const useEarningsApi = () => {
  const dispatch = useDispatch();
  const operatorId = useOperatorId();
  const { operators: operatorsApi } = useApi();
  const { logError } = useLogger('useEarningsApi');

  const fetchOperatorEarnings = useCallback(
    async (range: DatesRange) => {
      const { from, to } = getDatesRangeMap()[range];

      try {
        const earnings = await operatorsApi.getOperatorEarnings(operatorId, { from, to });

        dispatch(actions.setEarnings({ earnings, range }));
      } catch (error) {
        logError('Get Earnings data error', { error });
      }
    },
    [operatorsApi, logError, operatorId, dispatch],
  );

  const fetchOperatorKPI = useCallback(
    async (range: DatesRange) => {
      const { from, to } = getDatesRangeMap()[range];

      try {
        const fullKPI = await operatorsApi.getOperatorKPI(operatorId, { from, to });

        dispatch(actions.setKPI({ fullKPI, range }));
      } catch (error) {
        logError('Get Kpi data error', { error });
      }
    },
    [operatorsApi, logError, operatorId, dispatch],
  );

  return {
    fetchOperatorEarnings,
    fetchOperatorKPI,
  };
};
