import React, { memo, useCallback } from 'react';

import { DialogModel } from 'modules/domain/dialog';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = {
  status: boolean;
  operatorIsReadyForExplicit: boolean;
  clientId: string;
  animatorId: string;
};

export const ExplicitBanner = memo((props: Props) => {
  const { status, operatorIsReadyForExplicit, animatorId, clientId } = props;
  const { setDialogExplicitStatus } = DialogModel.hooks.useDialogExplicitStatus();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDialogExplicitStatus(event.target.checked, animatorId, clientId);
    },
    [animatorId, clientId, setDialogExplicitStatus],
  );

  if (operatorIsReadyForExplicit && !status) {
    return <div className={styles.layoutFix}>&nbsp;</div>;
  }

  return (
    <div {...testId('explicit-banner-container')} className={styles.container}>
      <label className={styles.label}>
        <input type="checkbox" checked={status} onChange={onChange} />
        <span>Explicit chat</span>
      </label>
    </div>
  );
});
