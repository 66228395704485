import React from 'react';

import styles from './index.module.css';

type Props = {
  penalty: number;
};
export const AutoFineMessage = (props: Props) => {
  const { penalty } = props;
  return (
    <div>
      <div className={styles.fineAmount}>-${Math.abs(penalty)}</div>
      <div>
        Got you! This answer is not appropriate, be more attentive. Your income has been reduced.
      </div>
    </div>
  );
};
