import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { useClickOutside } from 'modules/domain/common/hooks';

import styles from './index.module.css';
import { MenuItem, MenuItemProps } from './menu-item';

type Props = {
  options: MenuItemProps[];
  trigger: React.ReactNode;
  position: 'bottom-right' | 'top-right' | 'bottom-left' | 'right';
};

export const TooltipMenu = (props: Props) => {
  const { trigger, options, position } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = useCallback(() => setMenuOpen(false), []);
  const wrapperRef = useClickOutside<HTMLDivElement>({
    condition: menuOpen,
    action: closeMenu,
  });

  return (
    <div className={styles.menuContainer} ref={wrapperRef}>
      <div className={styles.trigger} onClick={() => setMenuOpen(open => !open)}>
        {trigger}
      </div>

      {menuOpen ? (
        <div
          className={cn(styles.menu, {
            [styles.bottomRight]: position === 'bottom-right',
            [styles.topRight]: position === 'top-right',
            [styles.bottomLeft]: position === 'bottom-left',
            [styles.right]: position === 'right',
          })}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              {...option}
              onClick={() => {
                option.onClick && option.onClick();
                !option.fileInput && !option.doNotClose && closeMenu();
              }}
              onChange={files => {
                option.onChange && option.onChange(files);
                closeMenu();
              }}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
