import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Dialog } from 'modules/domain/dialog/types';
import { DialogMessageDto } from 'modules/api/dto';

import { setLastBotMessage } from '../actions';

export const useLastBotMessageTracker = (currentDialog: Dialog) => {
  const { animatorId, messages } = currentDialog;
  const lastMessage: DialogMessageDto | undefined = messages[0];

  const dispatch = useDispatch();

  const changeLastBotMessage = useCallback(
    (lastBotMessage: boolean) => {
      dispatch(setLastBotMessage(lastBotMessage));
    },
    [dispatch],
  );

  useEffect(() => {
    const lastBotMessage =
      lastMessage?.sender === animatorId && lastMessage.meta.automation?.product === 'bot';

    changeLastBotMessage(lastBotMessage);

    return () => changeLastBotMessage(false);
  }, [lastMessage, animatorId, changeLastBotMessage]);
};
