import { useDispatch, batch } from 'react-redux';
import { useCallback } from 'react';

import { getDialogId } from 'modules/utils';

import { Dialog } from '../types';
import { actions } from '../actions';
import { AudioMessageTemplate } from '../audio';

import { useDialogAnalytics } from './use-dialog-analytics';

export const useDialogActions = () => {
  const dispatch = useDispatch();
  const { trackSearchDialog } = useDialogAnalytics();

  const findDialog = useCallback(
    async (attendeeId: string, animatorId: string) => {
      const id = getDialogId(animatorId, attendeeId);

      trackSearchDialog(animatorId, attendeeId);

      const dialog: Dialog = {
        serverId: 0,
        id,
        animatorId,
        attendeeId,
        messages: [],
        unread: false,
        answered: true,
        timestamp: new Date().getTime(),
        eventId: 999999,
        isExplicit: false,
        isExplicitPossibleForClient: false,
      };

      batch(() => {
        dispatch(actions.apiRequestSuccessDialogList([dialog], true));
        dispatch(actions.setFoundDialog(id));
      });
    },
    [dispatch, trackSearchDialog],
  );

  const resetFoundDialog = useCallback(() => {
    dispatch(actions.resetFoundDialog());
  }, [dispatch]);

  return {
    findDialog,
    resetFoundDialog,
  };
};

export const useDialogActionsById = (dialogId: string) => {
  const dispatch = useDispatch();

  const switchOffSearchMessages = useCallback(
    () => dispatch(actions.clearSearchedMessages(dialogId)),
    [dialogId, dispatch],
  );

  const removeMessage = useCallback(
    (tag: string) => dispatch(actions.removeMessageByTag(dialogId, tag)),
    [dialogId, dispatch],
  );

  const setAudioMessages = useCallback(
    (audioMessages: AudioMessageTemplate[]) =>
      dispatch(actions.setAudioMessages(dialogId, audioMessages)),
    [dialogId, dispatch],
  );

  const resetAudioMessages = useCallback(
    () => dispatch(actions.resetAudioMessages(dialogId)),
    [dialogId, dispatch],
  );

  return {
    switchOffSearchMessages,
    removeMessage,
    setAudioMessages,
    resetAudioMessages,
  };
};
