import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useScrollToMessageId } from 'modules/domain/dialog/hooks';
import { actions } from 'modules/domain/dialog/actions';

export function useScrollToMessage(messageRefs: {
  [key: string]: React.RefObject<HTMLDivElement>;
}) {
  const dispatch = useDispatch();
  const scrollToMessageId = useScrollToMessageId();

  useEffect(() => {
    if (scrollToMessageId && messageRefs[scrollToMessageId]) {
      // workaround about chrome bug with scrollintoview
      // https://github.com/facebook/react/issues/23396
      // we want to make sure the current scroll animation has completed
      // ideally we should refactor the scroll, later
      window.setTimeout(
        () =>
          messageRefs[scrollToMessageId].current?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          }),
        0,
      );
      dispatch(actions.setScrollToMessageId(null));
    }
  }, [messageRefs, scrollToMessageId, dispatch]);
}
