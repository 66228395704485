import React, { memo, PropsWithChildren, useCallback } from 'react';
// todo an obscure package (like 150 stars) doing something simple and without ts declarations
// should change it to something better
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Files from 'react-files';

import './styles/index.scss';

const MIN_SIZE = 0;
const MAX_SIZE = Infinity;
const MAX_FILES = Infinity;

type Props = PropsWithChildren<{
  accept: string[];
  onChange: (files: File[]) => void;
  maxFiles?: number;
}>;

export const FileInput = memo((props: Props) => {
  const { accept, onChange, maxFiles } = props;

  const handleChange = useCallback(
    newFiles => {
      if (!newFiles || !newFiles.length) return;

      onChange(newFiles);
    },
    [onChange],
  );

  return (
    <Files
      className="file-input-wrapper"
      onChange={handleChange}
      accepts={accept || ['*']}
      multiple
      maxFiles={maxFiles || MAX_FILES}
      maxFileSize={MAX_SIZE}
      minFileSize={MIN_SIZE}
      clickable
    >
      {props.children}
    </Files>
  );
});
