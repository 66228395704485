import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import {
  NotifyAboutTheTrapPayload,
  GetAutoGeneratedMessageAnswersPayload,
} from 'modules/api/payload';
import { useOperatorId } from 'modules/domain/auth';
import { useLogger } from 'modules/utils';

import { CopilotOption, DialogMessage } from '../types';
import { actions } from '../actions';

import { useCopilotState } from './use-dialog-selectors';

type Params = {
  animatorId: string;
  attendeeId: string;
  lastMessage?: DialogMessage;
};

export function useCopilot({ animatorId, attendeeId, lastMessage }: Params) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const operatorId = useOperatorId();
  const { dialogsMessagesAutomation: dialogsMessagesAutomationApi } = useApi();
  const { log } = useLogger('useCopilot');
  const { options: copilotOptions = [] } = useCopilotState();

  const currentLastMessageId = useRef<number | undefined>();

  useEffect(
    () => () => {
      dispatch(actions.resetCopilotState());
    },
    [dispatch],
  );

  useEffect(() => {
    const checkLastMessageNotChanged = () => lastMessage?.id === currentLastMessageId.current;

    if (checkLastMessageNotChanged()) {
      return;
    }
    currentLastMessageId.current = lastMessage?.id;

    const needToShowCopilot = !!lastMessage?.id && !lastMessage.outgoing;

    if (!needToShowCopilot) {
      dispatch(actions.resetCopilotState());
      setLoading(false);

      return;
    }

    setLoading(true);

    const payload: GetAutoGeneratedMessageAnswersPayload = {
      operatorId,
      animatorId,
      attendeeId,
      messageId: lastMessage.id,
    };

    dialogsMessagesAutomationApi
      .getAutoGeneratedMessageAnswers(payload)
      .then(copilotData => {
        // since chat state could change blazing fast there can be race conditions. To avoid them
        // we bind the request to a message id
        if (checkLastMessageNotChanged()) {
          log('info', 'Received copilot options', { copilotData, payload });

          dispatch(actions.copilotLoadSucceed(copilotData));
        }
      })
      .catch(error => {
        log('error', 'Failed to load copilot options', { error, payload });
      })
      .finally(() => {
        if (checkLastMessageNotChanged()) {
          setLoading(false);
        }
      });
  }, [
    dialogsMessagesAutomationApi,
    log,
    lastMessage?.id,
    lastMessage?.outgoing,
    animatorId,
    attendeeId,
    dispatch,
    operatorId,
  ]);

  const selectCopilotOption = useCallback(
    (option: CopilotOption) => {
      log('info', 'Copilot option select', { option, animatorId, attendeeId });
      dispatch(actions.copilotOptionClicked(option.text));
    },
    [dispatch, log, animatorId, attendeeId],
  );

  const notifyAboutTheTrap = useCallback(
    (option: CopilotOption) => {
      const payload: NotifyAboutTheTrapPayload = {
        animatorId,
        attendeeId,
        operatorId,
        trapText: option.text,
      };
      log('info', 'Notify about the copilot trap', { payload, option });
      dialogsMessagesAutomationApi.notifyAboutTheTrap(payload).catch(error => {
        log('error', 'Failed to notify about the copilot trap', { error, payload, option });
      });
    },
    [dialogsMessagesAutomationApi, log, animatorId, attendeeId, operatorId],
  );

  return { copilotOptions, loading, selectCopilotOption, notifyAboutTheTrap };
}
