import React from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

export const OptionSkeleton = () => (
  <div className={styles.optionSkeleton} {...testId('copilot-option-skeleton')}>
    {' '}
  </div>
);
