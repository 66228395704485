import { useState } from 'react';
import moment from 'moment/moment';

import { ModalModel } from 'modules/domain/modals';

import { useDaysWithMessages } from './index';

export function useCalendarNavigation() {
  const { activeModalData } = ModalModel.hooks.useSelectors<{
    timestamp: Date;
  }>();

  const [startOfActiveMonth, setStartOfActiveMonth] = useState<Date>(
    moment.utc(activeModalData?.timestamp).startOf('month').toDate(),
  );

  const availableDays = useDaysWithMessages();

  function slideMonthLeft() {
    setStartOfActiveMonth(moment.utc(startOfActiveMonth).add(-1, 'month').toDate());
  }

  function slideMonthRight() {
    setStartOfActiveMonth(moment.utc(startOfActiveMonth).add(1, 'month').toDate());
  }

  const today = moment.utc();
  const nextVisibleMonth = moment.utc(startOfActiveMonth).add(1, 'month');
  const earliestMessageMoment = availableDays[0] ? moment.utc(availableDays[0].date) : today;

  const visibleMonth = moment.utc(startOfActiveMonth);
  const canSlideLeft = visibleMonth.isSameOrAfter(earliestMessageMoment);
  const canSlideRight = today.isSameOrAfter(nextVisibleMonth, 'month');
  const currentDate = moment.utc(startOfActiveMonth).format('MMMM YYYY');

  return {
    slideMonthLeft,
    slideMonthRight,
    startOfActiveMonth,
    canSlideLeft,
    canSlideRight,
    currentDate,
  };
}
