import { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { useApi } from 'modules/api';
import { DayWithMessagesDto } from 'modules/api/dto';
import { GetDialogDaysWithMessagesQueryParams } from 'modules/api/payload';
import { ModalModel } from 'modules/domain/modals';
import { useLogger } from 'modules/utils';

export function useDaysWithMessages() {
  const [availableDays, setAvailableDays] = useState<DayWithMessagesDto[]>([]);
  const { dialogs: dialogsApi } = useApi();
  const { logError } = useLogger('useDaysWithMessages');
  const { activeModalData } =
    ModalModel.hooks.useSelectors<{ animatorId: string; recipientId: string }>();

  useEffect(() => {
    if (!activeModalData) {
      return;
    }

    const { animatorId, recipientId } = activeModalData;

    const params: GetDialogDaysWithMessagesQueryParams = {
      dateFrom: moment.utc().add(-1, 'year').format('YYYY-MM-DD'),
      dateTo: moment.utc().add(1, 'day').format('YYYY-MM-DD'),
    };

    dialogsApi
      .getDialogDaysWithMessages(animatorId, recipientId, params)
      .then(dates => setAvailableDays(dates))
      .catch(error => {
        logError('Failed to fetch days with messages', {
          error,
          animatorId,
          recipientId,
          params,
        });
      });
  }, [activeModalData, dialogsApi, logError]);

  return availableDays;
}
