import React from 'react';
import moment from 'moment/moment';

import styles from './index.module.css';

type Props = {
  tileDate: Date;
  activeDate: Date | null;
};
export const SelectedDateCircle = (props: Props) => {
  const { tileDate, activeDate } = props;
  if (!moment(tileDate).isSame(moment(activeDate), 'day')) {
    return null;
  }
  return (
    <div className={styles.circle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <circle cx="15" cy="15" r="14.5" stroke="currentColor" />
      </svg>
    </div>
  );
};
