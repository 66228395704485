import { EarningsDto, FullKPIDto } from 'modules/api/dto';
import { RootState } from 'modules/App/store/root-state';

import { namespace, DatesRange } from './types';

const getEarningsState = (state: RootState, range: DatesRange) => state[namespace][range];

export const getEarnings = (state: RootState, range: DatesRange): EarningsDto | null =>
  getEarningsState(state, range)?.earnings || null;

export const getFullKPI = (state: RootState, range: DatesRange): FullKPIDto | null =>
  getEarningsState(state, range)?.fullKPI || null;

export const selectors = {
  getEarnings,
  getFullKPI,
};
