import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { AudioMessageTemplateDto } from 'modules/api/dto';
import {
  DownloadAnimatorAudioFilePayload,
  DownloadClientAudioFilePayload,
} from 'modules/api/payload';
import { useLogger } from 'modules/utils';

import { AudioMessageTemplate } from '../types';

const mapDtoToModel = (dto: AudioMessageTemplateDto): AudioMessageTemplate => ({
  id: dto.id,
  basename: dto.baseName,
  text: dto.text,
  topic: dto.topic,
});

export const useAudioMessagesApi = () => {
  const { dialogsAudio: dialogsAudioApi, features: featuresApi } = useApi();
  const { logError } = useLogger('useAudioMessagesApi');

  const checkAudioMessagesAvailable = useCallback(
    async (attendeeId: string) => {
      try {
        const result = await featuresApi.getVoiceMessagesAvailability(attendeeId);
        return result.enabled;
      } catch (error) {
        logError(`Failed to get voice messages availability`, { error, attendeeId });
        return false;
      }
    },
    [featuresApi, logError],
  );

  const getAudioMessageTemplates = useCallback(
    async (animatorId: string, attendeeId: string) => {
      try {
        const templatesDto = await dialogsAudioApi.getAudioMessageTemplates(animatorId, attendeeId);
        return templatesDto.map(mapDtoToModel);
      } catch (error) {
        logError('Failed to get audio messages', { error, animatorId, attendeeId });
        return [];
      }
    },
    [dialogsAudioApi, logError],
  );

  const downloadAttendeeAudioFile = useCallback(
    async (payload: DownloadClientAudioFilePayload) => {
      try {
        return await dialogsAudioApi.downloadAttendeeAudioFile(payload);
      } catch (error) {
        logError('Failed to download client audio file', { payload, error });

        throw error; // need to throw an error so the audio file's download flow will know that exception happened
      }
    },
    [dialogsAudioApi, logError],
  );

  const downloadAnimatorAudioFile = useCallback(
    async (payload: DownloadAnimatorAudioFilePayload) => {
      try {
        return await dialogsAudioApi.downloadAnimatorAudioFile(payload);
      } catch (error) {
        logError('Failed to download animator audio file', { payload, error });

        throw error; // need to throw an error so the audio file's download flow will know that exception happened
      }
    },
    [dialogsAudioApi, logError],
  );

  return {
    getAudioMessageTemplates,
    downloadAttendeeAudioFile,
    downloadAnimatorAudioFile,
    checkAudioMessagesAvailable,
  };
};
