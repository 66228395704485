import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from './actions';

export const useAuthActions = () => {
  const dispatch = useDispatch();

  const authLogout = useCallback(() => dispatch(actions.authLogout()), [dispatch]);

  return {
    authLogout,
  };
};
