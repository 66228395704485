import { reducer } from './reducer';
import { actions } from './actions';
import { selectors } from './selectors';
import { useDictionaryApi } from './hooks';

export const DictionaryModel = {
  reducer,
  actions,
  selectors,
  hooks: {
    useDictionaryApi,
  },
};
