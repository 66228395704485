import React, { useState, useCallback } from 'react';
import cn from 'classnames';

import purpurLogo from 'images/purpur-logo-big.svg';
import purpurImageGeneral from 'images/purpur-image-general.svg';
import purpurImageErotic from 'images/purpur-image-erotic.svg';
import { getDialogId } from 'modules/utils';
import { DialogModel } from 'modules/domain/dialog';
import { useClickOutside } from 'modules/domain/common/hooks';
import { testId } from 'modules/utils/test-id';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { IconButton } from 'modules/components/common/button';

import { IcebreakerPickerIcon } from './icon';
import styles from './index.module.css';

type Props = {
  senderId: string;
  attendeeId: string;
};

export const IcebreakerPicker = (props: Props) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const { senderId, attendeeId } = props;

  const dialogId = getDialogId(senderId, attendeeId);
  const { sendMessage } = DialogModel.hooks.useDialogMessagesApi(dialogId);

  const wrapperRef = useClickOutside<HTMLDivElement>({
    condition: pickerOpen,
    action: () => setPickerOpen(false),
  });

  const onPickIcebreaker = useCallback(
    icebreakerType => {
      setPickerOpen(false);

      sendMessage({
        text: 'Sent you a PurPur Card',
        meta: { icebreakerType },
      }).catch(() => {
        showChatErrorToast(
          'Failed to send a PurPur Card. Please check your connection and try again later',
        );
      });
    },
    [sendMessage],
  );

  return (
    <div className={styles.pickerContainer}>
      {pickerOpen && (
        <div className={styles.tooltip} ref={wrapperRef}>
          <div className={styles.title}>
            Let&apos;s play <img src={purpurLogo} alt="purpur" />
          </div>
          <div className={styles.subtitle}>
            Send a PurPur Card to ask your partner a question and answer yourself once you get their
            reply
          </div>
          <div className={styles.images}>
            <div className={styles.eroticCard} onClick={() => onPickIcebreaker('erotic')}>
              <img
                className={cn(styles.purpurImage, styles.eroticImage)}
                src={purpurImageErotic}
                alt="erotic"
              />
            </div>
            <div className={styles.generalCard} onClick={() => onPickIcebreaker('general')}>
              <img
                className={cn(styles.purpurImage, styles.generalImage)}
                src={purpurImageGeneral}
                alt="general"
              />
            </div>
          </div>
          <div className={styles.legend}>
            <div>
              Spicy
              <br />
              questions
            </div>
            <div>
              Everything
              <br />
              questions
            </div>
          </div>
        </div>
      )}
      <IconButton
        {...testId('icebreaker-picker')}
        onClick={() => setPickerOpen(pickerOpen => !pickerOpen)}
      >
        <IcebreakerPickerIcon />
      </IconButton>
    </div>
  );
};
