import moment from 'moment';
import React, { useCallback } from 'react';
import cn from 'classnames';

import { useOnboarding, useUserField } from 'modules/domain/users/hooks';
import { useOperatorId } from 'modules/domain/auth';
import { ModalModel } from 'modules/domain/modals';
import { ModalNames } from 'modules/domain/modals/types';

import styles from './index.module.css';
import { CloseIcon } from './close-icon';
import { moreThanAYearAgo } from './moreThanYearAgo';

type Props = {
  timestamp: number;
  attachOnboarding: boolean;
  animatorId: string;
  recipientId: string;
  onTop: boolean;
};

export const DayIndicator = (props: Props) => {
  const { timestamp, attachOnboarding, onTop, animatorId, recipientId } = props;
  const { finishOnboarding } = useOnboarding();
  const operatorId = useOperatorId();
  const { openModal } = ModalModel.hooks.useModal();

  const onDayClick = useCallback(() => {
    if (moreThanAYearAgo(timestamp)) {
      return;
    }
    openModal(ModalNames.Calendar, {
      animatorId,
      recipientId,
      timestamp,
    });
    finishOnboarding();
  }, [timestamp, animatorId, recipientId, openModal, finishOnboarding]);

  const needOnboarding = useUserField(operatorId, 'needOnboarding');
  const needToShowOnboarding = attachOnboarding && needOnboarding;

  const timeString = moment(timestamp).calendar('', {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'MMM D YYYY',
  });

  return (
    <div
      className={cn(styles.container, {
        [styles.onTop]: onTop,
        [styles.onboarding]: needToShowOnboarding,
      })}
      onClick={onDayClick}
    >
      {timeString}
      {needToShowOnboarding ? (
        <div className={styles.onboardingHint} onClick={event => event.stopPropagation()}>
          Click on the dates to open the calendar and jump to a specific date!
          <CloseIcon className={styles.closeButton} onClick={finishOnboarding} />
        </div>
      ) : null}
    </div>
  );
};
