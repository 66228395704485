import { useCallback } from 'react';

import { useCurrentDialogId, useDialogMessagesApi } from 'modules/domain/dialog/hooks';
import { MessageType } from 'modules/domain/dialog/types';
import { useApi } from 'modules/api';
import { createMessageReference, parseDialogId, useLogger } from 'modules/utils';
import { GiftDto } from 'modules/api/dto';

import { getGiftReference, getGiftsPriceLimitFromDto } from '../helpers';

export const useGiftsApi = () => {
  const { gifts: giftsApi } = useApi();
  const { logError } = useLogger('useGiftsApi');
  const dialogId = useCurrentDialogId();
  const { animatorId, attendeeId } = parseDialogId(dialogId);
  const { sendMessage } = useDialogMessagesApi(dialogId);

  const getGifts = useCallback(async () => {
    try {
      return await giftsApi.getGifts(attendeeId);
    } catch (error) {
      logError('Fetch gifts error', { error, attendeeId });

      return [];
    }
  }, [giftsApi, attendeeId, logError]);

  const getGiftsPriceLimit = useCallback(async () => {
    try {
      const response = await giftsApi.getGiftsAllowedData(animatorId, attendeeId);

      return getGiftsPriceLimitFromDto(response);
    } catch (error) {
      logError('fetch gifts allowed data error', { error, animatorId, attendeeId });

      return getGiftsPriceLimitFromDto({ result: false });
    }
  }, [giftsApi, animatorId, attendeeId, logError]);

  const sendGift = useCallback(
    async (gift: GiftDto) => {
      try {
        await sendMessage({
          reference: createMessageReference(MessageType.GIFT, getGiftReference(gift), true),
          meta: { gift: gift.giftId },
        });

        return true;
      } catch (error) {
        logError('Send gift error', { error, gift });

        return false;
      }
    },
    [sendMessage, logError],
  );

  return { getGifts, getGiftsPriceLimit, sendGift };
};
